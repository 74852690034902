import './styles.css';

import React from 'react';

export default function Footer() {
    return (
        <section className="copyright">
            <div className="footer-container">
                <div className="row">
                    <div className="col-md-12 copyright-info">
                            <p>
                              <a href="https://thekingu.com" target="_blank">King Kristi</a>
                              <span className="sep"> | </span> <span>Copyright © {(new Date()).getFullYear()}</span>
                            </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
