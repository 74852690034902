import './styles.css';
import 'devextreme/dist/css/dx.dark.css';

import React from 'react';
import Footer from './Footer.js';
import Card from './Card.js';

import { images } from './asset/data.js';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.logo = require('./asset/the-kingu.png');
  }

  render() {
    return (
      <div>
        <img className='logo' src={this.logo} />
        <Card images={images}/>
        <Footer />
      </div>
    );
  }
}

export default App;