import './styles.css';
import 'devextreme/dist/css/dx.dark.css';

import React from 'react';

export default function Card({ images }) {
    return (
        <div className='card-page' >
            <div className="container">
                <div className="row">
                    {images.map((image) => (
                        <div className="col-md-6">
                            <img
                                className="tile-image"
                                src={require(`./asset/${image.filename}`)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
